<div class="d-flex flex-column px-4 py-2 border-dialog mb-0" mat-dialog-title>
  <ng-container *ngIf="personalModel; else loadingHeader">
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex flex-grow-1 align-items-center justify-content-start">
        <div class="d-flex justify-content-center p-relative">
          <input
            hidden
            type="file"
            accept="image/jpeg,image/png,image/jpg;capture=camera"
            (change)="fileChangeEvent($event)"
            #fileImage
            id="file"
          />
          <app-circle-image
            [width]="50"
            (click)="clickImageInput()"
            appDragAndDrop
            (fileDropped)="dropImagen($event)"
            [imageSource]="profileOwnImageUrl"
          >
          </app-circle-image>
          <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary" *ngIf="canEdit">
            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
          </button>
        </div>
        <div class="d-flex flex-column ml-2">
          <h5 class="mat-dialog-title my-0">{{ personalModel.Apellido }} {{ personalModel.Nombre }}</h5>
          <small class="gray-text-muted">{{ personalModel.Legajo.puestos?.[0]?.cargo?.nombre }}</small>
        </div>
        <mat-icon class="ml-2" appVcardTooltip [$downloadButton]="true" [$vCardData]="$vCard()">qr_code_2 </mat-icon>
      </div>
      <div class="d-none d-lg-flex gap-1" *ngIf="edicionColaboradorPermission">
        <button class="fix-size" *ngIf="edicion" [command]="cancelarCmd" mat-stroked-button color="primary">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button class="fix-size" [command]="guardarEditarCmd" mat-flat-button color="primary">
          {{ edicion ? ('GENERAL.SAVE' | translate) : ('GENERAL.EDIT' | translate) }}
        </button>
      </div>
      <span class="dialog-close-icon material-symbols-outlined ml-2 px-2" (click)="cancel()"> close </span>
    </div>
    <div class="d-flex d-lg-none gap-1 mt-2 justify-content-end" *ngIf="edicionColaboradorPermission">
      <button class="fix-size" *ngIf="edicion" [command]="cancelarCmd" mat-stroked-button color="primary">
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
      <button class="fix-size" [command]="guardarEditarCmd" mat-flat-button color="primary">
        {{ edicion ? ('GENERAL.SAVE' | translate) : ('GENERAL.EDIT' | translate) }}
      </button>
    </div>
  </ng-container>
</div>
<app-detalle-empleado
  (menuSelected)="menuSelected($event)"
  [personalModel]="personalModel"
  [backgroundColor]="'primary'"
  [modoEdicion]="edicion"
  [historial]="historial"
  [contactForm]="contactForm"
  [menuItem]="selectedMenu"
>
</app-detalle-empleado>
<ng-template #loadingHeader>
  <div class="d-flex flex-fill align-items-center justify-content-start">
    <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="w-50" count="1" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>
