import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, computed } from '@angular/core';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { ReactionButtonComponent } from 'src/app/shared/reactions/reaction-button/reaction-button.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { ReactionListSettings } from 'src/app/shared/reactions/reduced-reaction-action-list/reduced-reaction-action-list';

@Component({
  selector: 'app-reaction-list',
  templateUrl: './reaction-list.component.html',
  styleUrls: ['./reaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactionButtonComponent],
})
export class ReactionListComponent {
  @Input() public settings: ReactionListSettings | undefined;
  @Output() public emojiClicked = new EventEmitter<string>();

  public $reactions: Signal<Reaction[]> = computed(() =>
    this.settings?.fixedEmojiList?.length > 0
      ? this.settings.fixedEmojiList.map((emoji) => ({ emoji, quantity: 0 }))
      : this.reactionService.$reactions(),
  );

  constructor(public reactionService: ReactionService) {}

  public onClick(reaction: Reaction): void {
    this.emojiClicked.emit(reaction.emoji);
  }
}
