import { Injectable, Inject } from '@angular/core';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';
import { AuthTenantsResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authTenantsResponse.DTO';
import { AuthChangePasswordRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authChangePasswordRequest.DTO';
import { AuthNewUserRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authNewUserRequest.DTO';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceBackend {
  constructor(
    @Inject(FrontEndConfigService) protected Constants: FrontEndConfig,
    protected http: HttpClient,
  ) {}

  public async getAuthDemo(recaptchaToken: string): Promise<AuthResponseDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/auth/demo' + '?recaptchaToken=' + recaptchaToken).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertAuth(authRequestDTO: AuthRequestDTO): Promise<AuthResponseDTO> {
    const res = await this.http.post(this.Constants.apiURL + '/auth/', authRequestDTO).toPromise();
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertAuthTenants(authRequestDTO: AuthRequestDTO): Promise<AuthTenantsResponseDTO> {
    const res = await this.http.post(this.Constants.apiURL + '/auth/tenants', authRequestDTO).toPromise();
    const resDTO = new AuthTenantsResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getAuthRoles(authToken: string): Promise<AuthTenantsResponseDTO> {
    const res = await this.http
      .get<AuthTenantsResponseDTO>(this.Constants.apiURL + '/auth/roles', { headers: new HttpHeaders().set('Authorization', authToken) })
      .toPromise();
    const resDTO = new AuthTenantsResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateAuth(authResponseDTO: AuthResponseDTO): Promise<AuthResponseDTO> {
    const res = await this.http.put(this.Constants.apiURL + '/auth/', authResponseDTO).toPromise();
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateAuthChangePassword(authChangePasswordRequestDTO: AuthChangePasswordRequestDTO): Promise<AuthChangePasswordRequestDTO> {
    const res = await this.http.put(this.Constants.apiURL + '/auth/changePassword', authChangePasswordRequestDTO).toPromise();
    const resDTO = new AuthChangePasswordRequestDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertAuthTermsAccepted(): Promise<AuthResponseDTO> {
    const res = await this.http.post(this.Constants.apiURL + '/auth/termsAccepted', null).toPromise();
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertAuthValidateEmailCODE(code: string, authRequestDTO: AuthRequestDTO): Promise<AuthResponseDTO> {
    const res = await this.http.post(this.Constants.apiURL + '/auth/validateEmail/' + code, authRequestDTO).toPromise();
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertAuthLogout(authRequestDTO: AuthRequestDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/auth/logout', authRequestDTO).toPromise();
  }

  public async insertAuthNewAccount(authNewUserRequestDTO: AuthNewUserRequestDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/auth/newAccount', authNewUserRequestDTO).toPromise();
  }
}
