import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { take } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { Emoji } from 'src/app/lib/quill-emoji/emoji';
import { EmojiPickerContentComponent } from 'src/app/shared/emoji-mart-selector/emoji-picker-content.component';

// eslint-disable-next-line no-var
declare var EmojiMart: any;

export interface EmojiButtonConfig {
  icon?: string;
  type?: 'stroked' | 'icon';
}

@Component({
  selector: 'app-emoji-mart-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, OverlayModule],
  templateUrl: './emoji-mart-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiMartButtonComponent implements OnDestroy {
  @ViewChild('emojiButton') public emojiButton!: ElementRef<HTMLButtonElement>;
  @Input() public config: EmojiButtonConfig = {
    icon: 'add_reaction',
    type: 'stroked',
  };
  @Input() public disabled: boolean;
  @Input() public limitReached: boolean;
  @Output() public emojiSelect = new EventEmitter<Emoji>();
  @Output() public mouseEnter = new EventEmitter<void>();
  @Output() public mouseLeave = new EventEmitter<void>();

  private overlayRef?: OverlayRef;
  private picker?: HTMLElement;
  private isOpen = false;

  constructor(
    private appService: HeaderAppService,
    private overlay: Overlay,
    private destroyRef: DestroyRef,
    private zone: NgZone,
  ) {}

  public togglePicker($event: MouseEvent): void {
    if (!this.limitReached) {
      if (!this.picker) {
        this.initializePicker($event);
      } else if (this.isOpen) {
        this.closePicker();
      } else {
        this.showPicker($event);
      }
    }
  }

  public ngOnDestroy(): void {
    this.closePicker();
    this.picker?.remove();
  }

  private initializePicker($event: MouseEvent): void {
    const locale = this.appService.currentLanguage?.split('-')?.[0] ?? 'es';

    this.picker = new EmojiMart.Picker({
      onEmojiSelect: (emoji: Emoji): void => {
        this.emojiSelect.emit(emoji);
        this.closePicker();
      },
      onClickOutside: (): void => this.closePicker(),
      previewPosition: 'none',
      skinTonePosition: 'search',
      locale,
      theme: 'light',
    });

    this.showPicker($event);
  }

  private showPicker($event: MouseEvent): void {
    if (!this.picker) {
      this.initializePicker($event);
    }

    this.zone.run(() => {
      $event.preventDefault();
      $event.stopPropagation();
      const { x, y } = $event;
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5,
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -5,
          },
        ]);

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        width: '352px', // Standard emoji picker width
        height: '435px', // Standard emoji picker height
      });

      const componentRef = this.overlayRef.attach(new ComponentPortal(EmojiPickerContentComponent));
      componentRef.instance.picker = this.picker;
      componentRef.instance.emojiSelect.subscribe((emoji: Emoji) => {
        this.emojiSelect.emit(emoji);
        this.closePicker();
      });
      componentRef.instance.mouseEnter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.mouseEnter.emit();
      });
      componentRef.instance.mouseLeave.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.mouseLeave.emit();
      });

      this.overlayRef
        .backdropClick()
        .pipe(take(1))
        .subscribe(() => this.closePicker());
      this.isOpen = true;
    });
  }

  private closePicker(): void {
    this.overlayRef?.dispose();
    this.isOpen = false;
    this.picker = null;
  }
}
