<div mat-dialog-content>
  <div class="d-flex flex-column mb-4">
    <div class="d-flex flex-row justify-content-between">
      <div class="hidden-sm">
        <ng-container *ngTemplateOutlet="imageAndEmail; context: { imageWidth: 200, smaller: false }"></ng-container>
      </div>
      <div class="hidden-lg">
        <ng-container *ngTemplateOutlet="imageAndEmail; context: { imageWidth: 80, smaller: true }"></ng-container>
      </div>

      <ng-template #imageAndEmail let-imageWidth="imageWidth" let-smaller="smaller">
        <div class="d-flex flex-row align-items-center">
          <app-circle-image [width]="imageWidth" [fontSize]="'xx-large'" [personalLegajo]="personal" [openProfile]="false" class="mr-2">
          </app-circle-image>
          <div class="d-flex flex-column">
            <h4 class="m-0 d-flex align-items-center">
              {{ personal.nombreCompleto }}
              @if ($canSeeCompleteProfile()) {
                <span>
                  <mat-icon class="ml-2" appVcardTooltip [$downloadButton]="true" [$vCardData]="$vCard()">qr_code_2 </mat-icon>
                </span>
              }
            </h4>
            <p class="gray-text-muted smaller">{{ personal.legajo.puestos?.[0]?.cargo?.nombre }}</p>
          </div>
        </div>
      </ng-template>

      <span class="dialog-close-icon material-symbols-outlined pt-1 pl-2" [mat-dialog-close]="true"> close </span>
    </div>
  </div>
  <div class="d-flex flex-column m-0" *ngIf="$verDatosContacto()">
    <ng-container [ngTemplateOutlet]="identifiers"></ng-container>
    <p>
      {{ 'GENERAL.OFFICE' | translate }}:
      <strong>{{ personal.oficinaNombre || '-' }}</strong>
    </p>
    <p>
      {{ 'GENERAL.EMAIL' | translate }}:
      <strong>{{ personal?.email || '-' }}</strong>
    </p>
    <p>
      {{ 'GENERAL.PHONE' | translate }}:
      <strong>{{ personal.telefonos || '-' }} {{ personal.legajo.internNumber ? '(' + personal.legajo.internNumber + ')' : '' }}</strong>
    </p>
    <p>
      {{ 'PROFILE.BIRTH_DATE' | translate }}:
      <strong>{{ personal.fechaNacimiento ? (personal.fechaNacimiento | ngxdate: 'shortDateFull') : '-' }}</strong>
    </p>
  </div>
  <div class="d-flex flex-column m-0" *ngIf="!$verDatosContacto()">
    <ng-container [ngTemplateOutlet]="identifiers"></ng-container>
    <p>
      {{ 'PROFILE.BIRTH_DATE' | translate }}:
      <strong>{{ personal.fechaNacimiento ? (personal.fechaNacimiento | ngxdate: 'shortDateFull') : '-' }}</strong>
    </p>
    <p>
      {{ 'GENERAL.DO_NOT_SEE_CONTACT_DATA' | translate }}
    </p>
  </div>
  <ng-template #identifiers>
    <p>
      {{ 'PROFILE.FILE' | translate }}:
      <strong>{{ personal.legajo?.legajo || '-' }}</strong>
    </p>
    <p *ngIf="$canAccessIdentifier()">
      {{ 'PROFILE.ID_NUMBER' | translate }}:
      <strong>{{ personal.dni || '-' }}</strong>
    </p>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button *ngIf="$canSendMessage()" type="button" color="primary" (click)="openChat()" mat-stroked-button>
    {{ 'CHAT.SEND_MESSAGE' | translate }}
  </button>
  <button *ngIf="$canSeeCompleteProfile()" type="button" color="primary" (click)="openProfile()" mat-stroked-button>
    {{ 'PROFILE.DISPLAY_FULL_PROFILE' | translate }}
  </button>
</div>
