import { ChangeDetectionStrategy, Component, effect, ElementRef, input, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { QRCodeRenderersOptions, toCanvas } from 'qrcode';
import { QRCodeSettings } from './qrcode.types';

const DEFAULT_SETTINGS = Object.freeze({
  colorDark: '#000000ff',
  colorLight: '#ffffffff',
  cssClass: 'qrcode',
  errorCorrectionLevel: 'M',
  margin: 4,
  scale: 4,
  width: 100,
});

@Component({
  selector: 'app-qrcode',
  standalone: true,
  imports: [],
  template: `<div #qrcElement [class]="$settings()?.cssClass"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrcodeComponent {
  @ViewChild('qrcElement', { static: true }) public qrcElement: ElementRef;

  public $qrData = input.required<string>();
  public $settings = input<QRCodeSettings>();
  public context: CanvasRenderingContext2D | null = null;

  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {
    effect(() => {
      const mergedSettings = { ...DEFAULT_SETTINGS, ...(this.$settings() ?? {}) };

      this.createQRCode(this.$qrData(), mergedSettings);
    });
  }

  private async createQRCode(qrData: string, settings: QRCodeSettings): Promise<void> {
    try {
      if (!(qrData?.length > 0)) {
        throw new Error('Field `qrdata` is empty, set \'allowEmptyString="true"\' to overwrite this behaviour.');
      }

      const config = {
        color: {
          dark: settings.colorDark,
          light: settings.colorLight,
        },
        errorCorrectionLevel: settings.errorCorrectionLevel,
        margin: settings.margin,
        scale: settings.scale,
        width: settings.width,
      };

      const canvasElement: HTMLCanvasElement = this.renderer.createElement('canvas');
      this.context = canvasElement.getContext('2d');
      this.toCanvas(canvasElement, config)
        .then(() => {
          this.renderElement(canvasElement);
        })
        .catch((e) => {
          console.error('canvas error:', e);
        });
    } catch (e: unknown) {
      console.error('Error generating QR Code:', (e as Error).message);
    }
  }

  private toCanvas(canvas: HTMLCanvasElement, qrCodeConfig: QRCodeRenderersOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      toCanvas(canvas, this.$qrData(), qrCodeConfig, (error: Error | null | undefined) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  private renderElement(element: Element): void {
    Array.from(this.qrcElement?.nativeElement?.childNodes ?? []).forEach((node) => {
      this.renderer.removeChild(this.qrcElement.nativeElement, node);
    });
    this.renderer.appendChild(this.qrcElement.nativeElement, element);
  }
}
