import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompletitudItemModel } from 'src/app/app-common/perfil-empleado-shared/completitud-item.model';
import { CompletitudGrupoModel } from 'src/app/app-common/perfil-empleado-shared/completitud.grupo-model';

import { ImageFileDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageFile.DTO';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { CambiosHistoricosModelDTO } from 'src/app/ModelDTO/cambiosHistoricos.ModelDTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalCambioEstadoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstado.DTO';
import { TipoCambioEstadoPersonalDTO } from 'src/app/ModelDTO/DTO/tipoCambioEstadoPersonal.DTO';
import { LegajoDTO } from 'src/app/ModelDTO/DTO/legajo.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PaisServiceBackend } from 'src/app/ServiceBackend/pais.ServiceBackend';
import { PersonaCambioEstadolServiceBackend } from 'src/app/ServiceBackend/personaCambioEstadol.ServiceBackend';
import { PersonalServiceBackend } from 'src/app/ServiceBackend/personal.ServiceBackend';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';
import { TipoCambioEstadoPersonalServiceBackend } from 'src/app/ServiceBackend/tipoCambioEstadoPersonal.ServiceBackend';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { DomicilioDTO } from '@api/interfaces/domicilio.interface';
import { CompletitudPerfilModel } from './completitud-perfil.model';

@Injectable({
  providedIn: 'root',
})
export class PerfilEmpleadoSharedService {
  private cancelarEdicion = new Subject<void>();
  public cancelarEdicion$ = this.cancelarEdicion.asObservable();

  // Control de steps
  private step1Status = new BehaviorSubject<boolean>(false);
  public step1Status$ = this.step1Status.asObservable();

  private step3Status = new BehaviorSubject<boolean>(false);
  public step3Status$ = this.step1Status.asObservable();

  private empleadoArrivedSubject = new BehaviorSubject<PersonalModelDTO>(undefined);
  public empleadoArrived$ = this.empleadoArrivedSubject.asObservable();

  constructor(
    private paisServiceBackend: PaisServiceBackend,
    private personalServiceBackend: PersonalServiceBackend,
    private personalLegajoServiceBackend: PersonalLegajoServiceBackend,
    private personaCambioEstadolServiceBackend: PersonaCambioEstadolServiceBackend,
    private tipoCambioEstadoServiceBackend: TipoCambioEstadoPersonalServiceBackend,
    private translateService: TranslateService,
    private companyService: PerfilEmpresaService,
  ) {}

  public async editarPersonal(personalDTO: PersonalDTO): Promise<PersonalDTO> {
    const res = await this.personalServiceBackend.updatePersonalId(personalDTO.id, personalDTO);
    return res;
  }

  public async agregarPersonal(personalDTO: PersonalDTO): Promise<PersonalDTO> {
    const res = await this.personalServiceBackend.insertPersonal(personalDTO);
    return res;
  }

  public async obtenerPersonal(personaId: number): Promise<PersonalModelDTO> {
    const res = await this.personalServiceBackend.getPersonalId(personaId);
    return new PersonalModelDTO(res);
  }
  public async obtenerPersonalPorUserTypeId(userTypeId: number): Promise<PersonalModelDTO> {
    const res = await this.personalServiceBackend.getPersonalLegajoId(userTypeId);
    const personal = new PersonalModelDTO(res);
    this.empleadoArrivedSubject.next(personal);
    return personal;
  }

  public setPersonalModel(personal: PersonalModelDTO): void {
    this.empleadoArrivedSubject.next(personal);
  }

  public async guardarImagenPersonal(idPersonal: number, imagen: ImageEntityDTO): Promise<ImageEntityDTO> {
    return this.personalServiceBackend.insertPersonalIdImage(idPersonal, imagen);
  }

  public async actualizarPersonal(idPersonalLegajo: number, personalLegajo: PersonalDTO): Promise<PersonalDTO> {
    const res = await this.personalServiceBackend.updatePersonalId(idPersonalLegajo, personalLegajo);
    return res;
  }

  public async actualizarPersonalPerfil(personalLegajo: PersonalDTO): Promise<PersonalDTO> {
    const res = await this.personalServiceBackend.updatePersonalPerfil(personalLegajo);
    return res;
  }

  public async obtenerHistorialCambios(idPersonalLegajo: number): Promise<CambiosHistoricosModelDTO> {
    const res = await this.personalLegajoServiceBackend.getPersonalLegajosIdHistorialCambios(idPersonalLegajo);
    return new CambiosHistoricosModelDTO(res);
  }

  public async ingresarPersonal(personalLegajo: PersonalDTO): Promise<PersonalDTO> {
    const res = await this.personalServiceBackend.insertPersonal(personalLegajo);
    return res;
  }

  public async editarCambioHistorico(personalLegajoId: number, cambio: CambioDTO): Promise<CambioDTO> {
    const res = await this.personalLegajoServiceBackend.updatePersonalLegajosIdHistorialCambiosIDCambio(
      personalLegajoId,
      cambio.id,
      cambio,
    );
    return res;
  }

  public async eliminarCambioHistorico(personalLegajoId: number, cambio: CambioDTO): Promise<void> {
    await this.personalLegajoServiceBackend.deletePersonalLegajosIdHistorialCambiosIDCambio(personalLegajoId, cambio.id);
  }

  public async obtenerSuperiorSugerido(idCargo: number): Promise<PersonalLegajoBasicoDTO> {
    const res = await this.personalLegajoServiceBackend.getPersonalLegajosIDCargoSupervisorSugerido(idCargo);
    return res;
  }

  public async cambiarEstado(personaDTO: PersonalDTO, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Promise<PersonalCambioEstadoDTO> {
    const res = await this.personaCambioEstadolServiceBackend.insertCambioEstadoIdLegajo(personaDTO.legajo.id, personalCambioEstadoDTO);
    return res;
  }

  public async obtenerTiposDeCambioDeEstado(): Promise<Array<TipoCambioEstadoPersonalDTO>> {
    const res = await this.tipoCambioEstadoServiceBackend.getTiposCambioEstado();
    return res;
  }

  public async cambiarRolPersonalLegajo(personal: PersonalLegajoBasicoDTO): Promise<void> {
    await this.personalLegajoServiceBackend.updatePersonalLegajosIdCambioRol(personal.id, personal);
  }

  public async hacerPropietario(personal: PersonalLegajoBasicoDTO): Promise<void> {
    await this.personalLegajoServiceBackend.updatePersonalLegajosIdEstadoOwner(personal.id, personal);
  }

  public async invalidarToken(personal: LegajoDTO): Promise<void> {
    await this.personalLegajoServiceBackend.insertPersonalLegajosIdInvalidarToken(personal.id);
  }

  public async updateCambioEstado(cambioEstado: PersonalCambioEstadoDTO): Promise<PersonalCambioEstadoDTO> {
    const res = await this.personaCambioEstadolServiceBackend.updateCambioEstadoId(cambioEstado.id, cambioEstado);
    return res;
  }

  public async obtenerPersonalBusquedaAfipDNI(dni: string): Promise<PersonalDTO> {
    const res: PersonalDTO = await this.personalServiceBackend.getPersonalBusquedaPorAfipdocumento(dni);
    return res;
  }

  public async reenviarNuevoMail(personal: PersonalModelDTO): Promise<void> {
    await this.personalServiceBackend.insertPersonalIdReenviarNuevoMail(personal.Id);
  }

  public async generarDeclaracionJurada(personal: PersonalModelDTO): Promise<void> {
    await this.personalLegajoServiceBackend.insertPersonalLegajosIdDeclaracionDomicilio(personal.Legajo.id);
  }

  public async agregarFirmaOlografa(personal: PersonalModelDTO, imageFile: ImageFileDTO): Promise<ImageEntityDTO> {
    return this.personalServiceBackend.insertPersonalIdSignatureImage(personal.Id, imageFile);
  }

  public cancelarEdicionPersonal(): void {
    this.cancelarEdicion.next();
  }

  public updateStep1Status(status: boolean): void {
    this.step1Status.next(status);
  }

  public updateStep3Status(status: boolean): void {
    this.step3Status.next(status);
  }

  public getCompletitudPerfil(personalCompleto: PersonalDTO): CompletitudPerfilModel {
    const completitudPerfil = new CompletitudPerfilModel(this.translateService);

    completitudPerfil.personales = this.getCompletitudGrupo([
      new CompletitudItemModel(this.translateService.instant('PROFILE.ID_NUMBER'), personalCompleto?.dni ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.TIN'), personalCompleto?.cuil ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.LAST_NAME'), personalCompleto?.apellido ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.FIRST_NAME'), personalCompleto?.nombre ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.DATE_OF_BIRTH'), personalCompleto?.fechaNacimiento ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.GENDER'), personalCompleto?.genero ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.NATIONALITY'), personalCompleto?.nacionalidad?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.MARITAL_STATE'), personalCompleto?.estadoCivil ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.BLOOD_TYPE'), personalCompleto?.grupoSanguineo ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.STUDIES_LEVEL'), personalCompleto?.nivelEstudio?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.HEIGHT'), personalCompleto?.altura ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.HEAD_CIRCUMFERENCE'), personalCompleto?.circunferenciaCabeza ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.PANTS_SIZE'), personalCompleto?.tallePantalon ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.SHIRT_SIZE'), personalCompleto?.talleCamisa ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.SHOE_SIZE'), personalCompleto?.talleCalzado ?? null),
    ]);

    completitudPerfil.contacto = this.getCompletitudGrupo([
      new CompletitudItemModel(this.translateService.instant('PROFILE.PHONE_NUMBERS'), personalCompleto?.telefonos ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.EMAIL'), personalCompleto?.email ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.COMPANY_EMAIL'), personalCompleto?.legajo?.emailEmpresa ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.ADDRESS'), personalCompleto?.domicilio?.direccion ?? null),
      new CompletitudItemModel(this.translateService.instant('GENERAL.LOCATION'), personalCompleto?.domicilio?.localidad?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.ADDRESS'), personalCompleto?.domicilioResidencia?.direccion ?? null),
      new CompletitudItemModel(
        this.translateService.instant('GENERAL.LOCATION'),
        personalCompleto?.domicilioResidencia?.localidad?.id ?? null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.EMERGENCY_CONTACT_NAME'),
        personalCompleto?.contactoEmergencia?.nombreCompleto ?? null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.EMERGENCY_CONTACT_PHONE_NUMBER'),
        personalCompleto?.contactoEmergencia?.telefonos ?? null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.EMERGENCY_CONTACT_EMAIL'),
        personalCompleto?.contactoEmergencia?.email ?? null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.EMERGENCY_CONTACT_ADDRESS'),
        personalCompleto?.contactoEmergencia?.domicilio?.direccion ?? null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.EMERGENCY_CONTACT_LOCATION'),
        personalCompleto?.contactoEmergencia?.domicilio?.localidad?.id ?? null,
      ),
    ]);

    completitudPerfil.laborales = this.getCompletitudGrupo([
      new CompletitudItemModel(this.translateService.instant('PROFILE.FILE'), personalCompleto?.legajo?.legajo ?? null),
      new CompletitudItemModel(this.translateService.instant('EMPLOYEES.CONTRACT_TYPE'), personalCompleto?.legajo?.modalidad?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.OFFICE'), personalCompleto?.oficina?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.ENTRY_DATE'), personalCompleto?.legajo?.fechaIngreso ?? null),
      new CompletitudItemModel(this.translateService.instant('GENERAL.CURRENCY'), personalCompleto?.legajo?.currency?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('GENERAL.FORMS.MODALITY'), personalCompleto?.legajo?.modoTrabajo ?? null),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.AREA'),
        personalCompleto?.legajo?.puestos?.length > 0 ? personalCompleto.legajo.puestos[0].area?.id : null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.POSITION'),
        personalCompleto?.legajo?.puestos?.length > 0 ? personalCompleto.legajo.puestos[0].cargo?.id : null,
      ),
      new CompletitudItemModel(
        this.translateService.instant('PROFILE.CATEGORY'),
        personalCompleto?.legajo?.categoriaContratacion?.id ?? null,
      ),
      new CompletitudItemModel(this.translateService.instant('PROFILE.BANK'), personalCompleto?.banco?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('EMPLOYEES.BANK_ACCOUNT_TYPE'), personalCompleto?.bankAccountType?.id ?? null),
      new CompletitudItemModel(this.translateService.instant('PROFILE.ACCOUNT_NUMBER'), personalCompleto?.nroCuenta ?? null),
    ]);

    return completitudPerfil;
  }

  public async obtenerPaises(): Promise<Array<PaisDTO>> {
    return this.paisServiceBackend.getPaises();
  }

  public getVCard(employeeData: {
    name: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    role?: string;
    area?: string;
    birthDay?: string;
    address?: DomicilioDTO;
  }): string {
    // DO NOT CHANGE THIS STRING INDENTATION
    return `BEGIN:VCARD
VERSION:3.0
N:${employeeData.lastName};${employeeData.name};
FN:${employeeData.fullName}
EMAIL:${employeeData.email}
TEL:${employeeData.phone}
TITLE:${employeeData.role ?? ''}
ORG:${this.companyService.company?.name ?? ''};${employeeData.area ?? ''}
BDAY:${employeeData.birthDay ?? ''}
ADR:;;${employeeData.address?.direccion ?? ''};${employeeData.address?.localidad?.nombre ?? ''};${employeeData.address?.localidad?.provincia?.nombre ?? ''};${employeeData.address?.localidad?.codigoPostal ?? ''};${employeeData.address?.localidad?.provincia?.pais?.nombre ?? ''}
END:VCARD`;
  }

  private getCompletitudGrupo(items: CompletitudItemModel[]): CompletitudGrupoModel {
    const completitudGrupo = new CompletitudGrupoModel();
    items.forEach((item: CompletitudItemModel) => {
      const field = item.valor;
      if (field !== null && field !== undefined && (field.length > 0 || field > 0 || field instanceof Date)) {
        item.completado = true;
      }
      completitudGrupo.campos.push(item);
    });
    return completitudGrupo;
  }
}
