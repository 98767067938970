import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { CommentReactionDTO } from '@api/interfaces/comment-reaction.interface';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';
import { DateTime } from 'luxon';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { UserTeammateFile } from 'src/app/shared/circle-image/user-legajo.interface';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { parseStringReactions } from 'src/app/shared/shared-functions';

export interface ReactionInformation {
  value: string;
  timestamp: DateTime;
  teammates: number[];
  teammatesFullInformation: UserTeammateFile[];
}

@Injectable()
export class ReactionService {
  public $reactions: WritableSignal<Reaction[]> = signal([]);
  public $currentReaction: WritableSignal<string> = signal('');
  public $reactionLimitReached = computed(() => this.$reactions().length >= 10);

  private reactionsCache: { timestamp: DateTime; reactions: CommentReactionDTO[] } | undefined = undefined;
  private getReactionsEndpoint: () => Observable<CommentReactionDTO[]>;
  private updateReactionsEndpoint: (dto: NewCommentReactionDTO) => Observable<CurrentReactionDTO>;
  private deleteReactionsEndpoint: (emoji: string) => Observable<CurrentReactionDTO>;

  constructor(private headerService: HeaderAppService) {}

  public initialize(
    getReactionsEndpoint: () => Observable<CommentReactionDTO[]>,
    updateReactionsEndpoint: (dto: NewCommentReactionDTO) => Observable<CurrentReactionDTO>,
    deleteReactionsEndpoint: (emoji: string) => Observable<CurrentReactionDTO>,
  ): void {
    this.getReactionsEndpoint = getReactionsEndpoint;
    this.updateReactionsEndpoint = updateReactionsEndpoint;
    this.deleteReactionsEndpoint = deleteReactionsEndpoint;
  }

  public loadReactions(): Observable<CommentReactionDTO[]> {
    const now = DateTime.now();

    if (this.reactionsCache && now < this.reactionsCache.timestamp) {
      return of(this.reactionsCache.reactions);
    }

    return this.getReactionsEndpoint().pipe(
      tap((reactions) => {
        this.reactionsCache = {
          timestamp: DateTime.now().plus({ seconds: 10 }),
          reactions,
        };
      }),
      map(() => this.reactionsCache.reactions),
    );
  }

  public handleReaction(emoji: string): Observable<CurrentReactionDTO> {
    const alreadyAddedReaction = this.$reactions().some((r) => r.emoji === emoji);
    const addedByCurrentUser =
      this.reactionsCache?.reactions?.some(
        (reaction) => reaction.emoji === emoji && this.headerService.personalLegajoId === reaction.teammateId,
      ) || this.$currentReaction() === emoji;
    const previousReaction = this.$currentReaction();

    if (!alreadyAddedReaction || !addedByCurrentUser) {
      const dto = new NewCommentReactionDTO();
      dto.emoji = emoji;

      this.addLocalReaction(emoji, 1);
      this.$currentReaction.set(emoji);

      return this.updateReactionsEndpoint(dto).pipe(
        tap((res) => {
          this.$reactions.set(parseStringReactions(res.reactions));
        }),
        catchError(() => {
          this.addLocalReaction(emoji, -1);
          this.$currentReaction.set(previousReaction);
          return EMPTY;
        }),
      );
    }

    this.addLocalReaction(emoji, -1);
    this.$currentReaction.set('');

    return this.deleteReactionsEndpoint(emoji).pipe(
      tap((res) => {
        this.$reactions.set(parseStringReactions(res?.reactions ?? ''));
        this.$currentReaction.set(res?.currentReaction ?? '');
      }),
      catchError(() => {
        this.addLocalReaction(emoji, 1);
        this.$currentReaction.set(previousReaction);
        return EMPTY;
      }),
    );
  }

  private normalizeReaction = (reaction: string): string => reaction?.trim().replace(/\0/g, '').normalize();

  private addLocalReaction(emoji: string, quantity: number): void {
    const currentReaction = this.$reactions().find((reaction) => reaction.emoji === emoji);
    if (currentReaction !== undefined) {
      currentReaction.quantity += quantity;
      if (currentReaction.quantity === 0) {
        this.$reactions.update((reactions) => reactions.filter((x) => x.emoji !== currentReaction.emoji));
      }
    } else {
      this.$reactions.update((reactions) => [
        ...reactions,
        {
          emoji,
          quantity: 1,
        },
      ]);
    }

    let reactions = this.reactionsCache?.reactions ?? [];

    if (quantity > 0) {
      const name = this.headerService.userLogged.fullName;
      reactions.push({ id: 0, createDateTime: DateTime.now(), emoji, teammateId: this.headerService.personalLegajoId, teammateName: name });
    } else {
      reactions = reactions.filter((reaction) => reaction.emoji === emoji && reaction.teammateId === this.headerService.personalLegajoId);
    }

    this.reactionsCache = { reactions, timestamp: DateTime.now().plus({ seconds: 10 }) };
  }
}
