<app-reduced-reaction-list class="pointer" (click)="executeOpenReactionsDetails()" [reactions]="$reactions()" />
<div class="material-xsmall">
  <app-emoji-mart-button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [limitReached]="$limitReached()"
    (emojiSelect)="emojiSelected($event.native)"
    (mouseenter)="openReducedSelector()"
    (mouseleave)="scheduleCloseReducedSelector()"
  />
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="$reducedSelectorOpen()"
  [cdkConnectedOverlayPositions]="[
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  }
]"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
>
  <div class="likes-hover-menu" (mouseenter)="cancelCloseReducedSelector()" (mouseleave)="scheduleCloseReducedSelector()">
    <app-reaction-list (emojiClicked)="emojiSelected($event)" />
  </div>
</ng-template>
