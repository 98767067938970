import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Reaction } from 'src/app/shared/reactions/reaction';

@Component({
  selector: 'app-reduced-reaction-list',
  templateUrl: './reduced-reaction-list.component.html',
  styleUrls: ['./reduced-reaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ReducedReactionListComponent {
  @Input() public reactions: Reaction[];

  public get reactionCount(): number {
    return this.reactions.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0);
  }
}
