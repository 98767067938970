import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyParagraph',
  standalone: true,
})
export class EmptyParagraphPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return value;
    }
    return value.replace(/<p><\/p>/g, '<p><br></p>').replace(/&nbsp;/g, ' ');
  }
}
