<div mat-dialog-title class="d-flex flex-column">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <h3>{{ notificacion.title }}</h3>
    <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div class="gray-text-muted label-text">
      <small>
        {{ 'COMMUNICATIONS.CREATED_BY' | translate }} {{ notificacion.userName }} {{ 'COMMUNICATIONS.CONNECT_WITH_DATE' | translate }}
        {{ notificacion.creationDateTime | ngxdate: 'fullDateShortTime24' }}
      </small>
      <small *ngIf="notificacion.openDateTime">
        , {{ 'COMMUNICATIONS.OPEN_THE' | translate }} {{ notificacion.openDateTime | ngxdate: 'fullDateShortTime24' }}
      </small>
      <small *ngIf="notificacion.readDateTime">
        , {{ 'COMMUNICATIONS.READ_THE' | translate }} {{ notificacion.readDateTime | ngxdate: 'fullDateShortTime24' }}
      </small>
      <small *ngIf="notificacion.understoodDateTime">
        , {{ 'COMMUNICATIONS.CONFIRMED_UNDERSTANDING' | translate }} {{ notificacion.understoodDateTime | ngxdate: 'fullDateShortTime24' }}
      </small>
    </div>
    <app-priority-indicator [priority]="notificacion.priority"></app-priority-indicator>
  </div>
</div>
<mat-dialog-content>
  <div *ngIf="notificacion.notUnderstood">
    <small class="violet-color">
      {{ 'COMMUNICATIONS.NOT_UNDERSTANDING_INFO' | translate }}
    </small>
  </div>
  <div class="d-flex flex-row html-view-container">
    <quill-view-html class="text-prewrap break-word" [content]="notificacion.details | emptyParagraph"></quill-view-html>
  </div>
  <app-reduced-reaction-action-list *ngIf="notificacion.state === 1" class="my-2" (emojiSelect)="clickedEmoji($event)" />
  <div class="d-flex flex-row" *ngIf="archivos && archivos.length > 0">
    <app-file-chips [attachments]="archivos" [noFilesMessage]="'No hay archivos cargados'" [setOwner]="true"></app-file-chips>
  </div>
  <br />
  <div class="d-flex flex-row mb-3 justif-content-end">
    <div *ngIf="notificacion.state !== 1">
      <button mat-raised-button type="button" color="primary" [throttledExecution]="leiElComunicado">
        {{ 'COMMUNICATIONS.READ' | translate }}
      </button>
    </div>
    <div
      *ngIf="
        notificacion.disableUnderstanding === false &&
        notificacion.state === 1 &&
        !notificacion.understoodDateTime &&
        !notificacion.notUnderstood
      "
    >
      <button mat-raised-button type="button" color="primary" [throttledExecution]="comprendiElComunicado">
        {{ 'COMMUNICATIONS.UNDERSTOOD' | translate }}
      </button>
      <button mat-stroked-button type="button" class="ml-2" color="primary" [throttledExecution]="noComprendiElComunicado">
        {{ 'GENERAL.HELP' | translate }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="notificacion.state === 1">
    <app-comentarios
      [id]="notificacion.id"
      [comentarioHandler]="comentarioHandler"
      [canBeNotified]="[]"
      cantBeNotifiedKey="NO_NOTIFICATION_TEAMMATE_COMUNICATION"
    ></app-comentarios>
  </ng-container>
</mat-dialog-content>
