import { environment } from 'src/environments/environment';

export const urlAPI = environment.production ? 'https://backend.naaloo.com' : 'https://backend-qa.naaloo.com';
// : 'http://localhost:8881';

export const urlWeb = environment.production ? 'https://app.naaloo.com' : 'https://app-qa.naaloo.com';

export const urlWebPage = 'https://naaloo.com';
export const urlZendesk = 'https://naaloo.zendesk.com/hc/es-419';

export const appStore = 'https://apps.apple.com/us/app/naaloo/id6445893676';
export const playStore = 'https://play.google.com/store/apps/details?id=com.capasitio.naaloo';

// Chat
export const CHAT_REST_API_URL = environment.production
  ? `https://n1dgp5agmd.execute-api.us-east-1.amazonaws.com/prod`
  : `https://3y68lxb7h9.execute-api.us-east-1.amazonaws.com/dev`;
export const CHAT_WEBSOCKET_API_URL = environment.production
  ? 'wss://wg5muja9x4.execute-api.us-east-1.amazonaws.com/prod'
  : 'wss://riansym53m.execute-api.us-east-1.amazonaws.com/dev';
export const CHAT_WEBSOCKET_PROTOCOL = environment.production ? 'naaloo-protocol' : 'naaloo-protocol';

// Fullcalendar
export const FullCalendarKey = '0054018555-fcs-1700227698';

// Atlassian
export const STATUS_PAGE_ID = 'tjq1dlpc6svq';
export const STATUS_PAGE_API_ID = '7yzrt57k9yd2';
export const STATUS_PAGE_SIGNATURE_ID = 'wr373fnkgmhk';
export const STATUS_PAGE_URL = 'https://naaloo.statuspage.io';

export const searchDelay = 500;

// Breakpoints (deben coincidir con los que figuran en vars.scss)
export const BreakpointSM = '(max-width: 599.98px)';
export const BreakpointMD = '(max-width: 899.98px)';
export const BreakpointLG = '(max-width: 1199.98px)';
export const BreakpointXL = '(min-width: 1200px)';

export const googleApi = environment.production ? 'AIzaSyDJJsUMObdqRuYiiyOL8sHUejc_G_jv9dw' : 'AIzaSyDJJsUMObdqRuYiiyOL8sHUejc_G_jv9dw';

export const VAPIKEY = environment.production
  ? 'BBLK-VP4AjiEnOoEHKbURlJrCYVdYxtzyyKihEZvvOKR1IJiPQb9iZv16FtbPDL_HIgBjuuC34VfJMPK-jAjHr8'
  : 'BBLK-VP4AjiEnOoEHKbURlJrCYVdYxtzyyKihEZvvOKR1IJiPQb9iZv16FtbPDL_HIgBjuuC34VfJMPK-jAjHr8';

export const noCompanyImage = 'assets/img/company-area.png';
export const noProfileImage = 'assets/img/no-profile.png';
export const noEventImage = 'assets/img/no-event.jpg';
export const noFormImage = 'assets/img/no-form.jpg';

export class Constants {
  public static get apiURL(): string {
    return urlAPI;
  }

  public static get delaySearchMilliseconds(): number {
    return searchDelay;
  }
}

export const DATE_FORMATS = {
  parse: {
    dateInput: ['dd/MM/yyyy'],
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd MM yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export const confidencialImage = 'assets/img/logo-mini-blue.png';

export const svgIcons = {
  chevron_up: `<svg width="8" height="8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.457 8.07005L3.49199 16.4296C3.35903 16.569 3.28485 16.7543 3.28485 16.9471C3.28485 17.1398 3.35903 17.3251 3.49199 17.4646L3.50099 17.4736C3.56545 17.5414 3.64304 17.5954 3.72904 17.6324C3.81504 17.6693 3.90765 17.6883 4.00124 17.6883C4.09483 17.6883 4.18745 17.6693 4.27344 17.6324C4.35944 17.5954 4.43703 17.5414 4.50149 17.4736L12.0015 9.60155L19.4985 17.4736C19.563 17.5414 19.6405 17.5954 19.7265 17.6324C19.8125 17.6693 19.9052 17.6883 19.9987 17.6883C20.0923 17.6883 20.1849 17.6693 20.2709 17.6324C20.3569 17.5954 20.4345 17.5414 20.499 17.4736L20.508 17.4646C20.641 17.3251 20.7151 17.1398 20.7151 16.9471C20.7151 16.7543 20.641 16.569 20.508 16.4296L12.543 8.07005C12.4729 7.99653 12.3887 7.93801 12.2954 7.89801C12.202 7.85802 12.1015 7.8374 12 7.8374C11.8984 7.8374 11.798 7.85802 11.7046 7.89801C11.6113 7.93801 11.527 7.99653 11.457 8.07005Z" fill="#FFFFFF" stroke="#FFFFFF"/>
  </svg>`,
  chevron_down: `<svg width="8" height="8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.497 7.98903L12 15.297L4.503 7.98903C4.36905 7.85819 4.18924 7.78495 4.002 7.78495C3.81476 7.78495 3.63495 7.85819 3.501 7.98903C3.43614 8.05257 3.38462 8.12842 3.34944 8.21213C3.31427 8.29584 3.29615 8.38573 3.29615 8.47653C3.29615 8.56733 3.31427 8.65721 3.34944 8.74092C3.38462 8.82463 3.43614 8.90048 3.501 8.96403L11.4765 16.74C11.6166 16.8765 11.8044 16.953 12 16.953C12.1956 16.953 12.3834 16.8765 12.5235 16.74L20.499 8.96553C20.5643 8.90193 20.6162 8.8259 20.6517 8.74191C20.6871 8.65792 20.7054 8.56769 20.7054 8.47653C20.7054 8.38537 20.6871 8.29513 20.6517 8.21114C20.6162 8.12715 20.5643 8.05112 20.499 7.98753C20.3651 7.85669 20.1852 7.78345 19.998 7.78345C19.8108 7.78345 19.6309 7.85669 19.497 7.98753V7.98903Z" fill="#FFFFFF" stroke="#FFFFFF"/>
  </svg>`,
};

export const EvaluationTypeIds = {
  SELF_EVALUATION: 1,
  EVALUATION_90: 2,
};
