import { CompletitudItemModel } from 'src/app/app-common/perfil-empleado-shared/completitud-item.model';

export class CompletitudGrupoModel {
  public campos: CompletitudItemModel[] = [];

  public get completados(): number {
    return this.campos.filter((campo) => campo.completado).length;
  }

  public get total(): number {
    return this.campos.length;
  }

  public get porcentaje(): number {
    return (this.completados / this.total) * 100;
  }

  public toString(): string {
    return this.campos
      .filter((campo) => !campo.completado)
      .map((campo) => campo.campo)
      .join(', ');
  }
}
