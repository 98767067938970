import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NamedBlobDTO } from 'src/app/shared/interfaces/named-blob.dto';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { QrcodeComponent } from 'src/app/shared/qrcode/qrcode.component';
import { QRCodeSettings } from 'src/app/shared/qrcode/qrcode.types';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

@Component({
  selector: 'app-vcard-tooltip',
  standalone: true,
  imports: [QrcodeComponent, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './vcard-tooltip.component.html',
  styleUrl: './vcard-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VcardTooltipComponent {
  public $downloadButton = input(false);
  public $vCardData = input.required<string>();
  public $qRsettings = input<QRCodeSettings | undefined>();
  public $showButtonDownloadButton = computed(() => this.$downloadButton() && this.cordovaService.isCordovaApp);

  constructor(
    private fileSaver: FileDownloaderService,
    private cordovaService: CordovaService,
  ) {}

  public downloadVCard(): void {
    const blob = new Blob([this.$vCardData()], { type: 'text/vcard' });
    const namedBlob = new NamedBlobDTO();
    namedBlob.blob = blob;
    namedBlob.name = 'contact.vcf';
    this.fileSaver.saveAs(namedBlob);
  }
}
