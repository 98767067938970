import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, EventEmitter, HostListener, Input, Output, signal } from '@angular/core';
import { map, Observable, Subscription, timer } from 'rxjs';
import { EmojiMartButtonComponent } from 'src/app/shared/emoji-mart-selector/emoji-mart-button.component';
import { ReactionListComponent } from 'src/app/shared/reactions/reaction-list/reaction-list.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { ReactionsDetailModalService } from 'src/app/shared/reactions/reactions-detail-modal/reactions-detail-modal.service';
import { ReducedReactionListComponent } from 'src/app/shared/reactions/reduced-reaction-list/reduced-reaction-list.component';
import { throttledObservableGenerator, ThrottledObservableGeneratorData } from 'src/app/shared/utils/throttled-obsevable-generator';

export interface ReactionListSettings {
  fixedEmojiList?: string[];
}

@Component({
  selector: 'app-reduced-reaction-action-list',
  templateUrl: './reduced-reaction-action-list.html',
  styleUrls: ['./reduced-reaction-action-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, OverlayModule, ReducedReactionListComponent, EmojiMartButtonComponent, ReactionListComponent],
})
export class ReducedReactionActionListComponent {
  @Input() public settings: ReactionListSettings;
  @Output() public emojiSelect = new EventEmitter<string>();

  @HostListener('mouseenter')
  public loadFullReactionsDetail(): void {
    this.executeLoadEmojiDetails.executionSubject$.next();
  }

  public $reactions = computed(() => this.reactionService.$reactions());
  public $limitReached = computed(() => this.reactionService.$reactionLimitReached());
  public $reducedSelectorOpen = signal(false);
  public scrollStrategy = this.overlay.scrollStrategies.reposition();

  private executeReactionsDetail: ThrottledObservableGeneratorData<void>;
  private executeLoadEmojiDetails: ThrottledObservableGeneratorData<void>;
  private closeReducedSelectorSubscription: Subscription;

  constructor(
    private reactionService: ReactionService,
    private destroyRef: DestroyRef,
    private overlay: Overlay,
    private reactionsDetailService: ReactionsDetailModalService,
  ) {
    this.executeReactionsDetail = throttledObservableGenerator<void>(() => this.openReactionsDetails(), this.destroyRef);
    this.executeLoadEmojiDetails = throttledObservableGenerator<void>(() => this.reactionService.loadReactions(), this.destroyRef);
  }

  public emojiSelected($event: string): void {
    this.emojiSelect.emit($event);
  }

  public executeOpenReactionsDetails(): void {
    this.executeReactionsDetail.executionSubject$.next();
  }

  public openReducedSelector(): void {
    if (this.reactionService.$reactionLimitReached()) {
      this.$reducedSelectorOpen.set(true);
    }
  }

  public scheduleCloseReducedSelector(): void {
    this.cancelCloseReducedSelector();
    this.closeReducedSelectorSubscription = timer(1000).subscribe(() => {
      this.$reducedSelectorOpen.set(false);
    });
  }

  public cancelCloseReducedSelector(): void {
    this.closeReducedSelectorSubscription?.unsubscribe();
  }

  private openReactionsDetails = (): Observable<unknown> =>
    this.reactionService.loadReactions().pipe(map((reactions) => this.reactionsDetailService.openReactionsDetail(reactions)));
}
