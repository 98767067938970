import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Reaction } from 'src/app/shared/reactions/reaction';

@Component({
  selector: 'app-reaction-button',
  templateUrl: './reaction-button.component.html',
  styleUrls: ['./reaction-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule],
})
export class ReactionButtonComponent {
  @Input() public reaction: Reaction;
  @Output() public emojiClicked = new EventEmitter<Reaction>();
  @Output() public hoverButton = new EventEmitter<Reaction>();

  public onMouseEnter(): void {
    this.hoverButton.emit(this.reaction);
  }

  public onClick(): void {
    this.emojiClicked.emit(this.reaction);
  }
}
