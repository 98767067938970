import { TranslateService } from '@ngx-translate/core';
import { CompletitudGrupoModel } from 'src/app/app-common/perfil-empleado-shared/completitud.grupo-model';

export class CompletitudPerfilModel {
  public personales: CompletitudGrupoModel;
  public contacto: CompletitudGrupoModel;
  public laborales: CompletitudGrupoModel;

  public get total(): CompletitudGrupoModel {
    const total = new CompletitudGrupoModel();
    total.campos = [...this.personales.campos, ...this.contacto.campos, ...this.laborales.campos];
    return total;
  }
  constructor(private translateService: TranslateService) {
    this.personales = new CompletitudGrupoModel();
    this.contacto = new CompletitudGrupoModel();
    this.laborales = new CompletitudGrupoModel();
  }

  public toString(): string {
    let result = '';

    result += `${this.translateService.instant('GENERAL.PENDING')}: \n\n`;
    result += `${this.translateService.instant('PROFILE.PERSONAL_INFORMATION')}: `;
    result += this.personales.porcentaje < 100 ? this.personales.toString() : this.translateService.instant('PROFILE.COMPLETED');
    result += '\n\n';
    result += `${this.translateService.instant('PROFILE.CONTACT')}: `;
    result += this.contacto.porcentaje < 100 ? this.contacto.toString() : this.translateService.instant('PROFILE.COMPLETED');
    result += '\n\n';
    result += `${this.translateService.instant('PROFILE.WORKING_INFORMATION')}: `;
    result += this.laborales.porcentaje < 100 ? this.laborales.toString() : this.translateService.instant('PROFILE.COMPLETED');

    return result;
  }
}
