import { Injectable } from '@angular/core';
import { NamedBlobDTO as NamedBlobApiDTO } from '@api/interfaces/named-blob.dto';
import FileSaver from 'file-saver';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { NamedBlobDTO as lastNamedBlob } from 'src/app/shared/interfaces/named-blob.dto';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor(private cordovaService: CordovaService) {}

  public saveAs(namedBlob: NamedBlobDTO | NamedBlobApiDTO | lastNamedBlob): void {
    if (this.cordovaService.isCordovaApp) {
      this.cordovaService.downloadAndOpen(namedBlob);
    } else {
      FileSaver.saveAs(namedBlob.blob, namedBlob.name);
    }
  }
}
