import { inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentReactionDTO } from '@api/interfaces/comment-reaction.interface';
import {
  ReactionsDetailModalComponent,
  ReactionsDetailModalInformation,
} from 'src/app/shared/reactions/reactions-detail-modal/reactions-detail-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ReactionsDetailModalService {
  private dialog = inject(MatDialog);
  private ngZone = inject(NgZone);

  public openReactionsDetail(reactions: CommentReactionDTO[]): void {
    const inDialog = this.dialog.openDialogs?.length > 0;
    const data: ReactionsDetailModalInformation = { reactions };

    this.ngZone.run(() => {
      this.dialog.open(ReactionsDetailModalComponent, {
        panelClass: inDialog ? 'small-reaction-modal' : 'full-size-sm-600-lg-modal',
        data,
      });
    });
  }
}
