import { Directive, ElementRef, HostListener, input, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { QRCodeSettings } from 'src/app/shared/qrcode/qrcode.types';
import { VcardTooltipComponent } from 'src/app/app-common/perfil-empleado-shared/vcard-tooltip/vcard-tooltip.component';
import { timer } from 'rxjs';

@Directive({
  selector: '[appVcardTooltip]',
  standalone: true,
})
export class VcardTooltipDirective implements OnDestroy {
  @HostListener('mouseenter') public showTooltip(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.elementRef)
          .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]),
        hasBackdrop: false,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
      });

      const tooltipPortal = new ComponentPortal(VcardTooltipComponent);
      const tooltipRef = this.overlayRef.attach(tooltipPortal);

      // Set inputs dynamically
      tooltipRef.setInput('$downloadButton', this.$downloadButton());
      tooltipRef.setInput('$vCardData', this.$vCardData());
      tooltipRef.setInput('$qRsettings', { ...(this.$qRsettings() ?? {}), margin: 0, width: this.$qRsettings()?.width ?? 150 });

      // Add mouse events for the overlay content

      this.overlayRef.overlayElement.addEventListener('mouseenter', () => {
        this.componentHovered = true;
      });
      this.overlayRef.overlayElement.addEventListener('mouseleave', () => {
        this.componentHovered = false;
        this.hideTooltip();
      });
    }
  }

  @HostListener('mouseleave') public hideTooltip(): void {
    // Only close if we're not hovering the tooltip
    timer(100).subscribe(() => {
      if (!this.componentHovered) {
        this.overlayRef?.dispose();
        this.overlayRef = null;
      }
    });
  }

  public $vCardData = input.required<string>();
  public $downloadButton = input<boolean>(false);
  public $qRsettings = input<QRCodeSettings | undefined>();

  private overlayRef: OverlayRef | null = null;
  private componentHovered = false;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
  ) {}

  public ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }
}
