import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { CdkVirtualForOf, ScrollingModule } from '@angular/cdk/scrolling';
import { UserTeammateFile } from 'src/app/shared/circle-image/user-legajo.interface';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { DateTime } from 'luxon';
import { CommentReactionDTO } from '@api/interfaces/comment-reaction.interface';

export interface ReactionsDetailModalInformation {
  reactions: CommentReactionDTO[];
}

interface ReactionInformation {
  teammate: UserTeammateFile;
  creation: DateTime;
  emoji: string;
}

@Component({
  selector: 'app-reactions-detail-modal',
  standalone: true,
  imports: [
    CommonModule,
    CdkVirtualForOf,
    CircleImageComponent,
    MatTabsModule,
    MatDialogModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    NgxDatePipe,
  ],
  templateUrl: './reactions-detail-modal.component.html',
  styleUrls: ['./reactions-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionsDetailModalComponent implements OnInit {
  public $emojiTabs: WritableSignal<{ label: string; emoji?: string }[]> = signal([]);
  public reactions: ReactionInformation[] | undefined;
  public emojis: Map<string, ReactionInformation[]> | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ReactionsDetailModalInformation,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.reactions = this.buildReactionsList(this.data.reactions);
    this.emojis = this.buildReactions(this.reactions);
    this.$emojiTabs.set(this.buildLabels(this.emojis));
  }

  public emojiTeammates(emoji: string): ReactionInformation[] {
    return this.emojis?.get(emoji);
  }

  private buildReactionsList(reactions: CommentReactionDTO[]): ReactionInformation[] {
    return reactions
      .sort((a, b) => a.createDateTime.toMillis() - b.createDateTime.toMillis())
      .map(({ createDateTime, emoji, teammateId, teammateImage, teammateName }) => ({
        creation: createDateTime,
        emoji,
        teammate: {
          teammateFileId: teammateId,
          imgSource: teammateImage,
          nombreCompleto: teammateName,
          id: `${teammateId}`,
        },
      }));
  }

  private buildReactions(reactions: ReactionInformation[]): Map<string, ReactionInformation[]> {
    const emojis = new Map();

    reactions?.forEach(({ emoji, teammate, creation }) => {
      const reactionData = emojis.get(emoji) || [];

      reactionData.push({
        emoji,
        teammate,
        creation,
      });

      emojis.set(emoji, reactionData);
    });

    return emojis;
  }

  private buildLabels(reactions: Map<string, ReactionInformation[]>): { label: string; emoji?: string }[] {
    const emojiLabels = [];
    let totalReactions = 0;

    reactions.forEach((emojiReactions, emoji) => {
      totalReactions += emojiReactions.length;
      emojiLabels.push({ label: `${emoji} ${emojiReactions.length}`, emoji });
    });

    const allLabel = {
      label: `${this.translate.instant('GENERAL.ALL')} ${totalReactions}`,
    };

    return [allLabel, ...emojiLabels];
  }
}
