/* eslint-disable */
import Quill from 'quill';
const Link = Quill.import('formats/link') as any;

class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);
