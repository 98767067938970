import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { EventoBackendService } from '@api/services/evento-backend.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { BackgroundPhotoComponent } from 'src/app/app-common/background-photo/background-photo.component';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { EmptyParagraphPipe } from 'src/app/lib/empty-paragraph.pipe';
import { EventoDTO } from 'src/app/ModelDTO/DTO/evento.DTO';
import { EventoServiceBackend } from 'src/app/ServiceBackend/evento.ServiceBackend';
import { EmojiMartButtonComponent } from 'src/app/shared/emoji-mart-selector/emoji-mart-button.component';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { ReducedReactionActionListComponent } from 'src/app/shared/reactions/reduced-reaction-action-list/reduced-reaction-action-list';
import { AddToCalendarService } from 'src/app/shared/services/add-to-calendar.service';
import { parseStringReactions, sameDay } from 'src/app/shared/shared-functions';
import { SharedModule } from 'src/app/shared/shared.module';
import { throttledObservableGenerator, ThrottledObservableGeneratorData } from 'src/app/shared/utils/throttled-obsevable-generator';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  standalone: true,
  providers: [ReactionService],
  imports: [
    MatMenuModule,
    MatIconModule,
    NgIf,
    MatDividerModule,
    QuillModule,
    SharedModule,
    MatTooltipModule,
    BackgroundPhotoComponent,
    DatePipe,
    NgxDatePipe,
    TranslateModule,
    AsyncPipe,
    MatButtonModule,
    EmojiMartButtonComponent,
    ReducedReactionActionListComponent,
    EmptyParagraphPipe,
  ],
})
export class EventDetailComponent implements OnInit, OnChanges {
  @ViewChild('emojiMenuTrigger') public emojiMenuTrigger: MatMenuTrigger;
  @Input() public esAdmin: boolean;
  @Input() public evento: EventoDTO;
  @Output() public editPerformed = new EventEmitter();

  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public eventDate: string;
  public currentLanguage = this.headerService.currentLanguage;

  public get reactionTooltipKey(): string {
    return this.reactionService.$reactionLimitReached() ? 'COMMENTS.REACTION_LIMIT_REACHED' : 'COMMENTS.ADD_REACTION';
  }

  private executeClickedEmoji: ThrottledObservableGeneratorData<string>;

  constructor(
    private headerService: HeaderAppService,
    private addToCalendarService: AddToCalendarService,
    private eventServiceBackend: EventoServiceBackend,
    private eventBackendService: EventoBackendService,
    private router: Router,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    public reactionService: ReactionService,
  ) {
    const destroyRef = inject(DestroyRef);

    this.reactionService.initialize(
      () => this.eventBackendService.getEventoIdReactions(this.evento.id),
      (dto) => this.eventServiceBackend.updateEventoIdReactions(this.evento.id, dto),
      (emoji) => this.eventServiceBackend.deleteEventoIdReactionsEMOJIID(this.evento.id, emoji),
    );

    this.executeClickedEmoji = throttledObservableGenerator<string>((emoji) => this.reactionService.handleReaction(emoji), destroyRef);
  }

  public ngOnInit(): void {
    const dateLanguage = this.headerService.currentLanguage === 'en' ? this.headerService.currentLanguage : 'es-ar';
    this.eventDate = !this.evento.todoElDia
      ? this.datePipe.transform(this.evento.fechaInicio, 'HH:mm', undefined, dateLanguage)
      : this.translateService.instant('EVENTS.FORMS.ALL_DAY');
    if (this.evento.fechaInicio.getTime() !== this.evento.fechaFin.getTime()) {
      this.eventDate += ` ${this.translateService.instant('GENERAL.TO')} ${
        sameDay(this.evento.fechaInicio, this.evento.fechaFin)
          ? ''
          : this.datePipe.transform(this.evento.fechaFin, 'longDate', undefined, dateLanguage)
      } ${!this.evento.todoElDia ? this.datePipe.transform(this.evento.fechaFin, 'HH:mm', undefined, dateLanguage) : ''}`;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.evento?.currentValue) {
      this.reactionService.$reactions.set(parseStringReactions(this.evento.reactions));
    }
  }

  public openGoogleCalendar(): void {
    this.addToCalendarService.openGoogleCalendar(this.evento);
  }

  public downloadIcs(): void {
    this.addToCalendarService.downloadIcs(this.evento);
  }

  public editEvent(): void {
    this.router.navigate([`/admin/eventos/edit/${this.evento.id}`]);
    this.editPerformed.emit();
  }

  public clickedEmoji(emoji: string): void {
    this.executeClickedEmoji.executionSubject$.next(emoji);
    this.emojiMenuTrigger?.closeMenu();
  }
}
