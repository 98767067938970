/* eslint-disable @typescript-eslint/no-use-before-define */
import { Injectable } from '@angular/core';
import { RolePermissionState } from '@api/enums/role-permission-state.enum';
import { DateTime } from 'luxon';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { FeatureServiceBackend } from 'src/app/ServiceBackend/feature.ServiceBackend';
import { IFeatureFlag } from 'src/app/shared/feature-flags/flag.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagProviderService {
  constructor(
    private headerService: HeaderAppService,
    private companyService: PerfilEmpresaService,
    private featuresBackend: FeatureServiceBackend,
  ) {}

  public getFeatureFlags = async (): Promise<Map<string, IFeatureFlag>> => {
    const user = this.headerService.userLogged?.userName ? await this.headerService.refreshUser() : null;
    const company = await this.featuresBackend.getFeature();

    const permissions =
      user?.role?.permissions
        ?.filter((x) => x.state === RolePermissionState.Active)
        .reduce((map: Map<number, boolean>, rol) => {
          map.set(rol.permission.id, true);
          return map;
        }, new Map<number, boolean>()) ?? new Map<number, boolean>();

    const flags = new Map<string, IFeatureFlag>();

    company.forEach((feature) => {
      // feature activa si no tiene o aun no llegó la fecha de vencimiento
      const today = DateTime.now();
      const active = feature.due ? today <= DateTime.fromJSDate(feature.due) : true;
      flags.set(feature.name, {
        active,
        due: feature.due ?? null,
        limit: feature.limit,
      });
    });

    // 41: Acceso al perfil básico del colaborador
    if (permissions.get(41) === true) {
      flags.set(featureReducedTeammateProfileAccess, { active: true, due: null });
    }

    // 406: Devuelve las ausencias de todo el personal filtrado (/ausencias/personal/filtradas)
    if (permissions.get(406) === true) {
      flags.set(featureAusenciasEquipo, { active: true, due: null });
      flags.set(featurePoliticas, { active: true, due: null });
    }

    // 565: Retorna las tipos de ausencias de un colaborador (/ausencias/personalLegajo/{ID:int}/tipos)
    if (!permissions.get(565)) {
      flags.set(featurePoliticas, { active: false, due: null });
    }

    // 415: Solicita la baja de la empresa. (/empresas/{ID:int})
    if (permissions.get(415) === true) {
      flags.set(featureBorradoEmpresa, { active: true, due: null });
    }

    // 844: Accede a informacion del status de la empresa (/empresas/{ID}/status)
    if (permissions.get(844) === true) {
      flags.set(featureCompanyStatus, { active: true, due: null });
    }

    //  80: Modifica el rol del personal (/personalLegajos/{ID:int}/cambioRol)
    if (permissions.get(380) === true) {
      flags.set(featureCambioRol, { active: true, due: null });
    }

    // 272: Devuelve una lista de Evento (/evento)
    if (permissions.get(272) === true) {
      flags.set(featureAltaEvento, { active: true, due: null });
    }

    // 435: Devuelve la cuota de todos los archivos. (/files/totalQuote)
    if (permissions.get(435) === true) {
      flags.set(featureQuoteFile, { active: true, due: null });
    }

    // 451: Retorna todos los cuestionarios según filtros (/cuestionarios)
    if (permissions.get(451) === true) {
      flags.set(featureCuestionario, { active: true, due: null });
    }

    // 266: Devuelve las sanciones del personal indicado. (/compliance/sancion/{ID})
    if (permissions.get(266) === true) {
      flags.set(featureSancion, { active: true, due: null });
    }

    // 346: Retorna las entregas pendientes agrupadas (/entrega/agrupadas)
    if (permissions.get(346) === true) {
      flags.set(featureEntregas, { active: true, due: null });
    }

    // 503: Retorna las capacitaciones pendientes agrupadas (/capacitacion/agrupadas)
    if (permissions.get(503) === true) {
      flags.set(featureCapacitaciones, { active: true, due: null });
    }

    //  135: Metodo para obtener documentación del personal, los administradores tienen la opción de filtrar por usuario (/biblioteca)
    if (permissions.get(135) === true) {
      flags.set(featureDocumentos, { active: true, due: null });
    }

    // 416: Devuelve las notificaciones leidas y no leidas por tipo (/user/notifications/staticsByType)
    if (permissions.get(416) === true) {
      flags.set(featureComunicacion, { active: true, due: null });
    }

    // 362: Devuelve indicadores de ausencia (/indicadores/ausencias)
    if (permissions.get(362) === true) {
      flags.set(featureAnalitica, { active: true, due: null });
    }

    // 26: Modifica la entidad de tipo Empresa. (/empresas/{ID})
    if (permissions.get(26) === true) {
      flags.set(featureEmpresa, { active: true, due: null });
    }

    // 1199: Obtener todos los usuarios del sistema (/users/)
    if (permissions.get(1199) === true) {
      flags.set(featureGetUsers, { active: true, due: null });
    }

    // 19: Devuelve los usuarios que contengan en su username el texto especificado en el parametro contiene (/users/autoComplete)
    if (permissions.get(19) === true) {
      flags.set(featureConfiguracion, { active: true, due: null });
    }

    // 30: Crea una entidad de tipo Personal (/personal)
    if (permissions.get(30) === true) {
      flags.set(featureAltaColaborador, { active: true, due: null });
    }

    // 31: Modifica la entidad de tipo Personal (/personal/{ID})
    if (permissions.get(31) === true) {
      flags.set(featureEdicionColaborador, { active: true, due: null });
    }

    // 469: Agrega la firma electrónica de un colaborador a un documento adjunto existente. (/attachmentsFirma/{ID:int}/firmar)
    if (permissions.get(469) === true) {
      flags.set(featureFirmaDigital, { active: true, due: null });
    }

    // 545: Cambia a vista de fichaje (/personalLegajos/vistaFichaje)
    if (permissions.get(545) === true) {
      flags.set(featureVistaFichaje, { active: true, due: null });
    }

    // 552: Hace fichar a un colaborador por QR (/fichajes/qr)
    if (permissions.get(552) === true) {
      flags.set(featureFichajeQr, { active: true, due: null });
    }

    // 36: Devuelve todos los fichajes entre las fechas dadas. (/fichajes)
    if (permissions.get(36) === true) {
      flags.set(featureFichajeAdministrador, { active: true, due: null });
    }

    // 38: Toma el fichaje del usuario actual. (/fichajes)
    if (permissions.get(38) === true) {
      flags.set(featureFichajeColaborador, { active: true, due: null });
    }

    // 315: Devuelve los datos para generar el gráfico del organigrama (/personalLegajos/organigrama)
    if (permissions.get(315) === true) {
      flags.set(featureOrganigrama, { active: true, due: null });
    }

    // 572: Devuelve los movimientos de cuenta corriente de un personal (/personalLegajos/{ID:int}/cuentasCorrientes)
    if (permissions.get(572) === true) {
      flags.set(featureMovimientosCC, { active: true, due: null });
    }

    // 605: Solo para el colaborador, devuelve informacion de contacto de cualquiera en la nomina (COLABORADOROTROPERSONAL)
    if (permissions.get(605) === true) {
      flags.set(featureColaboradorDatosContacto, { active: true, due: null });
    }

    // 824: Obtener el saldo de un tipo de ausencia para un colaborador (/personalLegajos/{ID:int}/saldoAusencia/{IDAusencia:int})
    if (permissions.get(824) === true) {
      flags.set(featureSaldoCC, { active: true, due: null });
    }

    // 640: Devuelve los feedbacks del personal indicado. (/feedback/personalLegajo/{ID})
    if (permissions.get(640) === true) {
      flags.set(featureFeedback, { active: true, due: null });
    }

    // 829: Devuelve los feedbacks de todos los colaboradores (/feedback)
    if (permissions.get(829) === true) {
      flags.set(featureAllFeedbacks, { active: true, due: null });
    }

    // 1129: Devuelve las notificaciones leidas y no leidas por tipo (/comunications)
    if (permissions.get(1129) === true) {
      flags.set(featureAllCommunications, { active: true, due: null });
    }

    // 639: Devuelve los personales con la cantidad de feedback enviados, recibidos y su último feedback (/feedback/conFeedback)
    if (permissions.get(639) === true) {
      flags.set(featureMuroFama, { active: true, due: null });
    }

    // 589: Devuelve los feedbacks recibidos del usuario logueado (/personalLegajos/feedback/recibidos)
    if (permissions.get(589) === true) {
      flags.set(featureFeedbackRecibido, { active: true, due: null });
    }

    // 590: Devuelve los feedbacks enviados del usuario logueado (/personalLegajos/feedback/enviados)
    if (permissions.get(590) === true) {
      flags.set(featureFeedbackEnviado, { active: true, due: null });
    }

    // 297: Genera una solicitud Ausencia (/solicitudAusencia)
    if (permissions.get(297) === true) {
      flags.set(featureSolicitarAusencia, { active: true, due: null });
    }

    // 833: Crea una planificación de envíos de un cuestionario (/cuestionarios/planificacion)
    if (permissions.get(833) === true) {
      flags.set(featureFormsAdministrador, { active: true, due: null });
    }

    // 453: Retorna los cuestionarios del usuario actual según filtros (/cuestionarios/usuarioActual)
    if (permissions.get(453) === true) {
      flags.set(featureForms, { active: true, due: null });
    }

    // 845: Guarda la imagen de la firma ológrafa del colaborador. (/personal/{ID}/signatureImage)
    if (permissions.get(845) === true) {
      flags.set(featureFirmaOlografa, { active: true, due: null });
    }

    // 861: Enviar mail de activación de beneficio (/partner/activarBeneficio)
    if (permissions.get(861) === true) {
      flags.set(featureParners, { active: true, due: null });
    }

    // 876: Permite a supervisores enviar comunicados a colaboradores fuera de sus supervisados (NOTIFICACIONSUPERVISORESOTRASAREAS)
    if (permissions.get(876) === true) {
      flags.set(featureComunicacionSupervisores, { active: true, due: null });
    }

    // 886: returns the current users tasks (/tasks/currentUser)
    if (permissions.get(886) === true) {
      flags.set(featureTasks, { active: true, due: null });
    }

    // 939: Devuelve oferta de acuerdo al link (/reclutamientoPublico/reclutamientoOferta/{LINK})
    if (permissions.get(939) === true) {
      flags.set(featureRecruitment, { active: true, due: null });
    }

    // 407: Devuelve las ausencias propias filtradas (/ausencias/propias)
    if (permissions.get(407) === true) {
      flags.set(featureAusenciasPropias, { active: true, due: null });
    }

    // 278: Devuelve los eventos filtrados entre fechas (/evento/filtrado)
    if (permissions.get(278) === true) {
      flags.set(featureEventos, { active: true, due: null });
    }

    // 903: returns task count pending by teammate (/tasks/pendingByTeammate)
    if (permissions.get(903) === true) {
      flags.set(featureTasksAdministrador, { active: true, due: null });
    }

    // 399: Envia una notificación a las personas pertenecientes a las areas y sectores (/personalLegajos/notificar)
    if (permissions.get(399) === true) {
      flags.set(featureEnviarComunicacion, { active: true, due: null });
    }

    // 574: Oculta inicio, fin de contrato y Antiguedad del personal (/personal/legajo/{ID})
    if (permissions.get(574) === true) {
      flags.set(featureInformacionContractual, { active: true, due: null });
    }

    // 445: Control solicitud ausencia vs cc (/personalLegajos)
    if (permissions.get(445) === true) {
      flags.set(featureLimitLicense, { active: true, due: null });
    }

    // 40: Muestra Gestión del tiempo al colaborador (/fichajes)
    if (permissions.get(40) === true) {
      flags.set(featureTimeManagement, { active: true, due: null });
    }

    // 1027: Cargar manualmente su propio fichaje (/fichajes/manual-own)
    if (permissions.get(1027) === true) {
      flags.set(featureOwnManualClocking, { active: true, due: null });
    }

    // 1004: Devuelve una lista de CompetenceCategory (/competences)
    if (permissions.get(1004) === true) {
      flags.set(featurePerformance, { active: true, due: null });
    }

    // 474: Carga documentacion con analisis (/biblioteca/documentacionConAnalisis)
    if (permissions.get(474) === true) {
      flags.set(featureBulkDocumentDistribution, { active: true, due: null });
    }

    // 606: Puede acceder solo a sus subordinados (/personalLegajos)
    if (permissions.get(606) === true) {
      flags.set(featureOnlyAccessOwnTeam, { active: true, due: null });
    }

    // 607: Puede aprobar sus propias ausencias (/personalLegajos)
    if (permissions.get(607) === true) {
      flags.set(featureApproveOwnAbsence, { active: true, due: null });
    }

    // 609: Puede acceder a los documentos de empresa (/personalLegajos)
    if (permissions.get(609) === true) {
      flags.set(featureAccessCompanyDocuments, { active: true, due: null });
    }

    // 659: Devuelve un listado de objetivos (/cuestionarios)
    if (permissions.get(659) === true) {
      flags.set(featurePerformanceGoals, { active: true, due: null });
    }

    // 842: Puede acceder a los recibos de haberes
    if (permissions.get(842) === true) {
      flags.set(featureSalary, { active: true, due: null });
    }

    // 1101: Asigna el resultado general a evaluados en una evaluación global
    if (permissions.get(1101) === true) {
      flags.set(featureCreateBehaviourEvaluation, { active: true, due: null });
      flags.set(featureConfirmEvaluationResult, { active: true, due: null });
    }

    if (permissions.get(42) === true) {
      flags.set(featureCanAccessTeammateIdentifier, { active: true, due: null });
    }

    if (permissions.get(1142) === true) {
      flags.set(featureCanGetSocialPosts, { active: true, due: null });
    }

    if (permissions.get(1144) === true) {
      flags.set(featureCanCreateSocialPost, { active: true, due: null });
    }

    // 378: Retorna las categorias de documentos segun tipo indicado
    if (permissions.get(378) === true) {
      flags.set(featureCanGetCategoryType, { active: true, due: null });
    }

    // 218: Carga de documentos de usuarios o empresa
    if (permissions.get(218) === true) {
      flags.set(featureCanAddDocuments, { active: true, due: null });
    }

    // 1219: Alta de objetivo
    if (permissions.get(1219) === true) {
      flags.set(featureCanCreateGoal, { active: true, due: null });
    }

    this.setCountryFlags(flags);

    return flags;
  };

  private setCountryFlags(flags: Map<string, IFeatureFlag>): void {
    const isMexicanCompany = this.companyService.company?.pais?.id === 122;
    if (!isMexicanCompany) {
      flags.set(countryFeatureHayGrade, { active: true, due: null });
    }
  }
}

// User permision based
export const featureGetUsers = 'featureGetUsers';
export const featureReducedTeammateProfileAccess = 'featureReducedTeammateProfileAccess';
export const featureFormsAdministrador = 'featureFormsAdministrador';
export const featureTasksAdministrador = 'featureTasksAdministrador';
export const featureEventos = 'featureEventos';
export const featureAusenciasPropias = 'featureAusenciasPropias';
export const featureSolicitarAusencia = 'featureSolicitarAusencia';
export const featureAusenciasEquipo = 'featureAusenciasEquipo';
export const featureBorradoEmpresa = 'featureBorradoEmpresa';
export const featureCambioRol = 'featureCambioRol';
export const featureAltaEvento = 'featureAltaEvento';
export const featureQuoteFile = 'featureQuoteFile';
export const featureCuestionario = 'featureCuestionario';
export const featureSancion = 'featureSancion';
export const featureFirmaDigital = 'featureFirmaDigital';
export const featureEntregas = 'featureEntregas';
export const featureCapacitaciones = 'featureCapacitaciones';
export const featureDocumentos = 'featureDocumentos';
export const featureComunicacion = 'featureComunicacion';
export const featureAnalitica = 'featureAnalitica';
export const featureEmpresa = 'featureEmpresa';
export const featureConfiguracion = 'featureConfiguracion';
export const featureAltaColaborador = 'featureAltaColaborador';
export const featureEdicionColaborador = 'featureEdicionColaborador';
export const featureVistaFichaje = 'featureVistaFichaje';
export const featureFichajeAdministrador = 'featureFichajeAdministrador';
export const featureFichajeColaborador = 'featureFichajeColaborador';
export const featureFichajeQr = 'featureFichajeQr';
export const featureOrganigrama = 'featureOrganigrama';
export const featurePoliticas = 'featurePoliticas';
export const featureMovimientosCC = 'featureMovimientosCC';
export const featureColaboradorDatosContacto = 'featureColaboradorDatosContacto';
export const featureSaldoCC = 'featureSaldoCC';
export const featureFeedback = 'featureFeedback';
export const featureMuroFama = 'featureMuroFama';
export const featureFeedbackRecibido = 'featureFeedbackRecibido';
export const featureFeedbackEnviado = 'featureFeedbackEnviado';
export const featureForms = 'featureForms';
export const featureFirmaOlografa = 'featureFirmaOlografa';
export const featureCompanyStatus = 'featureCompanyStatus';
export const featureParners = 'featureParners';
export const featureTasks = 'featureTasks';
export const featureRecruitment = 'featureRecruitment';
export const featureComunicacionSupervisores = 'featureComunicacionSupervisores';
export const featureEnviarComunicacion = 'featureEnviarComunicacion';
export const featureInformacionContractual = 'featureInformacionContractual';
export const featureLimitLicense = 'featureLimitLicense';
export const featureTimeManagement = 'featureTimeManagement';
export const featureOwnManualClocking = 'featureOwnManualClocking';
export const featurePerformance = 'featurePerformance';
export const featureBulkDocumentDistribution = 'featureBulkDocumentDistribution';
export const featureAccessCompanyDocuments = 'featureAccessCompanyDocuments';
export const featureApproveOwnAbsence = 'featureApproveOwnAbsence';
export const featureOnlyAccessOwnTeam = 'featureOnlyAccessOwnTeam';
export const featurePerformanceGoals = 'featurePerformanceGoals';
export const featureSalary = 'featureSalary';
export const featureCreateBehaviourEvaluation = 'featureCreateBehaviourEvaluation';
export const featureConfirmEvaluationResult = 'featureConfirmEvaluationResult';
export const featureAllFeedbacks = 'featureAllFeedbacks';
export const featureAllCommunications = 'featureAllCommunications';
export const featureCanAccessTeammateIdentifier = 'featureCanAccessTeammateIdentifier';
export const featureCanGetSocialPosts = 'featureCanGetSocialPosts';
export const featureCanCreateSocialPost = 'featureCanCreateSocialPost';
export const featureCanGetCategoryType = 'featureCanGetCategoryType';
export const featureCanAddDocuments = 'featureCanAddDocuments';
export const featureCanCreateGoal = 'featureCanCreateGoal';

// Feature based

export const companyFeatureWebsocket = 'companyFeatureWebsocket';
export const companyFeatureChat = 'companyFeatureChat';
export const companyFeatureChatExtensions = 'companyFeatureChatExtensions';
export const companyFeatureRecruitment = 'companyFeatureRecruitment';
export const companyFeatureRecruitmentOfferLimit = 'companyFeatureRecruitmentOfferLimit';
export const companyFeatureClockChecksum = 'companyFeatureClockChecksum';
export const companyFeatureWebPushNotifications = 'companyFeatureWebPushNotifications';
export const companyFeatureAppUpdateReminder = 'companyFeatureAppUpdateReminder';
export const companyFeatureSocialProfile = 'companyFeatureSocialProfile';

// Country based
export const countryFeatureHayGrade = 'countryFeatureHayGrade';
