import { HttpResponse } from '@angular/common/http';

export class NamedBlobDTO {

    public blob: Blob;
    public name: string;

    public get mimeType(): string { return this.cordovaMimeType(); }

    public setBlobNameFromHttpResponse(httpResponse: HttpResponse<Blob>): void {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        this.name = result.replace(/"/g, '');
    }

    private cordovaMimeType(): string {
        const extension = this.getExtension();
        switch (extension) {
            case 'ics':
              return 'text/calendar';
            case 'pdf':
                return 'application/pdf';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vcard':
                return 'text/vcard';
            default:
                return 'text/plain';
        }
    }

    private getExtension(): string | null | undefined {
        if (this.name) {
            return  this.name?.split('.').pop();
        }

        return null;
    }
}
