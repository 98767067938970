import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';

export class TenantResponseDTO implements IEntityDTO {
  id = '';
  name = '';
  image = '';
  token = '';
  hasMoreTenants: boolean;
  userTypeId: number;
  hasRoles: boolean;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.name != null) {
      this.name = jsonObj.name;
    }
    if (jsonObj.image != null) {
      this.image = jsonObj.image;
    }
    if (jsonObj.token != null) {
      this.token = jsonObj.token;
    }
    if (jsonObj.hasMoreTenants != null) {
      this.hasMoreTenants = jsonObj.hasMoreTenants;
    }
    if (jsonObj.userTypeId != null) {
      this.userTypeId = jsonObj.userTypeId;
    }
    if (jsonObj.hasRoles != null) {
      this.hasRoles = jsonObj.hasRoles;
    }
  }
}
