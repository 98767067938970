import Quill, { Module } from 'quill';
import Toolbar from 'quill/modules/toolbar';
import data, { EmojiMartData } from '@emoji-mart/data';
import { EmojiOptions } from './emoji-options';
import { Emoji } from './emoji';

// eslint-disable-next-line no-var
declare var EmojiMart: any;

export class EmojiModule extends Module<Partial<EmojiOptions>> {
  private readonly toolbar: Toolbar;
  private readonly toolbarButton: HTMLButtonElement | null;
  private sideButton: HTMLButtonElement;

  private picker?: HTMLElement;

  constructor(quill: Quill, options: Partial<EmojiOptions>) {
    super(quill, {
      ...options,
      theme: 'light',
      data: data as EmojiMartData,
    });

    this.toolbar = this.quill.getModule('toolbar') as Toolbar;
    this.toolbarButton = this.toolbar?.container?.querySelector('button.ql-emoji') ?? null;

    if (this.toolbarButton) {
      this.toolbarButton.innerHTML = this.getEmojiButtonSvg();
      this.addClickHandler(this.toolbarButton);
    }
    // Create side button, need to wait for render to check if it's readonly
    setTimeout(() => {
      if (!this.isReadOnly()) {
        this.sideButton = this.createSideButton();
        this.quill.container.appendChild(this.sideButton);
        this.sideButton.innerHTML = this.getEmojiButtonSvg();
        this.addClickHandler(this.sideButton);
      }
    }, 10);
  }

  private closeDialog(): void {
    this.picker?.remove();
    this.picker = undefined;
  }

  private openDialog(buttonElement: HTMLElement): void {
    this.picker = new EmojiMart.Picker({
      onEmojiSelect: (emoji: Emoji): void => this.selectEmoji(emoji),
      onClickOutside: (event: MouseEvent): void => this.onClickOutside(event),
      locale: this.getBrowserLanguage(),
      ...this.options,
      previewPosition: 'none',
      skinTonePosition: 'search',
    }) as unknown as HTMLElement;

    document.body.appendChild(this.picker);

    const position = this.calculatePosition(buttonElement);
    this.picker.style.top = position.top;
    this.picker.style.left = position.left;

    this.picker.style.position = 'absolute';
    this.picker.style.zIndex = '1001';
  }

  private isReadOnly(): boolean {
    const editorElement = this.quill.container.querySelector('.ql-editor');
    return editorElement?.getAttribute('contenteditable') === 'false';
  }

  private getBrowserLanguage(): string {
    return navigator.language.split('-')[0].toLowerCase();
  }

  private selectEmoji(emoji: Emoji): void {
    const emojiDelta = this.quill.insertText(this.quill.getSelection(true).index, emoji.native, 'user');

    this.closeDialog();

    // Set Input position after the emoji
    setTimeout(() => {
      this.quill.setSelection(this.quill.getSelection(true).index + emojiDelta.length());
    });
  }

  private onClickOutside(event: MouseEvent): void {
    const clickedElement = event.target as Element;
    const isToolbarButton = this.toolbarButton?.contains(clickedElement);
    const isSideButton = this.sideButton?.contains(clickedElement);

    if (!isToolbarButton && !isSideButton) {
      this.closeDialog();
    }
  }

  private createSideButton(): HTMLButtonElement {
    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'ql-emoji-side';
    button.style.position = 'absolute';
    button.style.right = '10px';
    button.style.top = '50%';
    button.style.transform = 'translateY(-50%)';
    button.style.background = 'transparent';
    button.style.border = 'none';
    button.style.cursor = 'pointer';
    return button;
  }

  private getEmojiButtonSvg(): string {
    return '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>';
  }

  private calculatePosition(buttonElement: HTMLElement): { top: string; left: string } {
    const rect = buttonElement.getBoundingClientRect();
    const pickerWidth = 352; // Default emoji-mart picker width
    const windowWidth = window.innerWidth;
    const spaceOnRight = windowWidth - rect.right;
    const spaceOnLeft = rect.left;

    const top = `${rect.top + 25}px`;
    let left: string;

    // Check if screen is too small or if there's not enough space on either side
    if (windowWidth <= pickerWidth || (spaceOnRight < pickerWidth && spaceOnLeft < pickerWidth)) {
      // Center the picker
      const centeredPosition = Math.max(0, (windowWidth - pickerWidth) / 2);
      left = `${centeredPosition}px`;
    } else if (spaceOnRight >= pickerWidth || spaceOnRight >= spaceOnLeft) {
      // Open to the right
      left = `${rect.left}px`;
    } else {
      // Open to the left
      left = `${rect.left - pickerWidth}px`;
    }

    return { top, left };
  }

  private addClickHandler(button: HTMLButtonElement): void {
    button.addEventListener('click', (event) => {
      const buttonElement = event.target as HTMLElement;
      if (!this.picker && buttonElement) {
        this.openDialog(buttonElement);
      } else {
        this.closeDialog();
      }
    });
  }
}
