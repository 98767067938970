import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { WebSocketService } from './services/web-socket.service';

@Injectable()
export class AWSInterceptor implements HttpInterceptor {
  constructor(private webSocketService: WebSocketService) {}
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (this.isAwsEvent(error.url) && error.status === 401) {
          this.webSocketService.refreshToken();
          return EMPTY;
        }
        return throwError(() => error);
      }),
    );
  }

  private isAwsEvent(url: string): boolean {
    return url?.includes('execute-api.us-east-1.amazonaws.com');
  }
}
