<h1 mat-dialog-title class="d-flex flew-row justify-content-between align-items-center">
  <span>{{ 'GENERAL.REACTIONS' | translate }}</span>
  <span class="dialog-close-icon material-symbols-outlined" mat-dialog-close> close </span>
</h1>
<div mat-dialog-content>
  <mat-tab-group>
    <mat-tab *ngFor="let tab of $emojiTabs(); let allReactions = first" [label]="tab.label">
      <ng-template matTabContent>
        <cdk-virtual-scroll-viewport itemSize="50" class="virtual-scroll-viewport m-2">
          <div class="reactions-grid">
            <ng-container *ngIf="allReactions; else emojiList">
              <ng-container *cdkVirtualFor="let reaction of reactions">
                <ng-container
                  *ngTemplateOutlet="reactionRow; context: { teammate: reaction.teammate, emoji: reaction.emoji, date: reaction.creation }"
                />
              </ng-container>
            </ng-container>
            <ng-template #emojiList>
              <ng-container *cdkVirtualFor="let reaction of emojiTeammates(tab.emoji)">
                <ng-container
                  *ngTemplateOutlet="reactionRow; context: { teammate: reaction.teammate, emoji: tab.emoji, date: reaction.creation }"
                />
              </ng-container>
            </ng-template>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #reactionRow let-teammate="teammate" let-emoji="emoji" let-date="date">
  <div class="emoji-profile-container">
    <app-circle-image [width]="40" [showLabel]="false" [imageSource]="teammate.imgSource" [personalLegajo]="teammate" />
    <span class="image-emoji">{{ emoji }}</span>
  </div>
  <div class="teammate-cell">
    <strong>
      {{ teammate.nombreCompleto }}
    </strong>
    <small>
      {{ date | ngxdate: 'fullDateShortTime' }}
    </small>
  </div>
</ng-template>
